import React, { useState } from "react";
import "./contactUs.css";
import { CiInstagram } from "react-icons/ci";
import { FaFacebookF, FaSpinner, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import contactUsBanner from "../../assets/images/banners/Contact Us.png";
import contactFav from "../../assets/images/contact_fav.png";
import BannerCard from "../../components/Card/BannerCard";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitData = (templateParams) => {
    return axios
      .post(
        "https://api.shareholdersgcb.com/api/royal-food/contact-us",
        templateParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setFormData({
          fullName: "",
          email: "",
          message: "",
        });
        setPhone("");
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const templateParams = {
      name: formData.fullName,
      email: formData.email,
      phone: phone,
      message: formData.message,
    };

    toast.promise(
      submitData(templateParams),
      {
        pending: 'Processing your request...',
        success: 'Your request has been successfully sent!',
        error: 'Something went wrong. Please try again.'
      }
    ).finally(() => {
      setLoading(false); // Optional: to handle any state changes after the promise settles
    });
    
  };

  return (
    <>
      <BannerCard title="Contact Us" subtitle="Find Royal Food" image={contactUsBanner} />
      {/* <nav className="contact-us-sub-menu-container position-relative">
      <div className='bg-image'>
          <img src={contactUsBanner} className='w-100 h-auto d-none d-md-block' alt='' />
          <img src={contactUsBannerMobile} className='w-100 h-auto d-block d-md-none' alt='' />
        </div>
        <div className="sub-menu-container">
          <span className="sub-menu-title">Contact Us</span>
          <span>Find Royal Food</span>
        </div>
      </nav> */}

      <div className="contact-us-wrapper position-relative">
        <div className="contact-left-center-shadow"></div>
        <div className="contact-bg-logo">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <img src={contactFav} className="h-auto w-100 contact-bg-fav" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="contactUs-container container-xxl">
          <div className="contact-form-container">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label>Full Name*</label>
                  <div className="input-box">
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Your Name"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Email*</label>
                  <div className="input-box">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email Address"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group phone-group">
                <label>Phone*</label>
                <PhoneInput
                  country={"us"}
                  inputClass="phone-input-field bg-black"
                    containerClass="input-box"
                  dropdownClass="phone-dropdown"
                  value={phone}
                  onChange={setPhone}
                  required
                />
              </div>

              <div className="form-group">
                <label>Message*</label>
                <div className="input-box">
                  <textarea
                    name="message"
                    placeholder="Please enter your inquiry here"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
              </div>

              <button type="submit" className="submit-btn" disabled={loading}>
                 {loading ? <FaSpinner className="spinner" /> : "Submit"}
              </button>
            </form>
          </div>

          <div className="contactUs_div">
            <div>
              <span className="find_us">Find Us</span> Here
            </div>
            <div className="join_div">Join Us in the Heart of the City</div>
            <div className="find_us_div">
              <span className="span_title">LOCATION</span>
              <span>Lüleburgaz/Kırklareli, Türkiye</span>
            </div>
            <div className="find_us_div">
              <span className="span_title">EMAIL</span>
              <span> Info@royalfb.com</span>
            </div>
            <div className="find_us_div">
              <span className="span_title">PHONE NUMBER</span>
              <span> +90 (212) 593 63 63</span>
            </div>
            <div className="find_us_div">
              <span className="span_title">LET'S CONNECT</span>
              <span className="social_media_icons">
                <span className="social_media_icons">
                  <a
                    href="https://www.facebook.com/tr.royalfood"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="https://www.instagram.com/royalfoodtr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CiInstagram />
                  </a>
                  <a
                    href="https://www.youtube.com/@RoyalFoodTR"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube />
                  </a>
                  <a
                    href="https://www.tiktok.com/@royalfoodtr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTiktok />
                  </a>
                  <a
                    href="https://x.com/royalfoodtr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaXTwitter />
                  </a>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
