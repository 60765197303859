import React from 'react'

function BannerCard({title,subtitle,image}) {
  console.log(image);
  return (
    <div className="banner">
      <img src={image} alt='' />
      <div className="text-box">
        <h2 className="title">{title}</h2>
        <p className="subtitle">{subtitle}</p>
      </div>
    </div>

    // <img src="https://www.susaron.es/wp-content/uploads/SLIDER_SUSARON-COLESTEROL-2200x600px.jpg" style={{width:'100%',height:'auto'}} />
    // <nav className="banner" style={{ backgroundImage: `url('${image}')` }}>
    //     <div className="text-box"><h2 className="title">{title}</h2> <p className='subtitle'>{subtitle}</p></div>
    // </nav>
  )
}

export default BannerCard