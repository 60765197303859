import React, { useEffect, useState } from 'react';
import "./ourProducts.css";
import gulfDrinkImage from "../../assets/images/Energy.jpg";
import gulfDrinkImageMobile from "../../assets/images/responsive_products/Gulf energy drink.jpg";
import gcColaImage from "../../assets/images/GC Cola.jpg";
import gcColaImageMobile from "../../assets/images/responsive_products/GC_Cola.png";
import juiceImage from "../../assets/images/juices.jpg";
import juiceImageMobile from "../../assets/images/responsive_products/Juices.png";
import sunFlowerImage from "../../assets/images/Sun flower oil.jpg";
import sunFlowerImageMobile from "../../assets/images/responsive_products/sunflower_oil.jpg";
import oliveOilImage from "../../assets/images/Olive Oil.jpg";
import oliveOilImageMobile from "../../assets/images/responsive_products/olive_oil.jpg";
import downArrow from "../../assets/images/product icon.png";
import { FaFacebookF, FaYoutube, FaTiktok, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import BannerCard from '../../components/Card/BannerCard';
import OurProductBanner from '../../assets/images/banners/Our Products.png';
import TextAnimation from '../../components/Animation/TextAnimation';


const ProductsPage = () => {
  const { t } = useTranslation();

  const [gulfDrinkSrc, setGulfDrinkSrc] = useState(gulfDrinkImage);
  const [gcColaSrc, setGcColaSrc] = useState(gcColaImage);
  const [juiceSrc, setJuiceSrc] = useState(juiceImage);
  const [sunFlowerSrc, setSunFlowerSrc] = useState(sunFlowerImage);
  const [oliveOilSrc, setOliveOilSrc] = useState(oliveOilImage);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setGulfDrinkSrc(gulfDrinkImageMobile);
        setGcColaSrc(gcColaImageMobile);
        setJuiceSrc(juiceImageMobile);
        setSunFlowerSrc(sunFlowerImageMobile);
        setOliveOilSrc(oliveOilImageMobile);
      } else {
        setGulfDrinkSrc(gulfDrinkImage);
        setGcColaSrc(gcColaImage);
        setJuiceSrc(juiceImage);
        setSunFlowerSrc(sunFlowerImage);
        setOliveOilSrc(oliveOilImage);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set the initial images

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <BannerCard title="Our Products" subtitle="Energy drinks, Juice, Oil..." image={OurProductBanner} />
      {/* <nav className="our-products-sub-menu-container ">
        <div className="sub-menu-container"><span className="sub-menu-title">Our Products</span> <span></span></div>
      </nav> */}

      <div className="products-container">
        <TextAnimation />
        <div className="gulf-drink-container">
          <img src={gulfDrinkSrc} alt="Gulf Energy Drink" className="gulf-drink-image" />
          <img src={downArrow} alt="Gulf Energy Drink" className="down-arrow first" />
          <div className="gulf-drink-content">
            <h1 className="gulf-drink-title">Gulf Energy Drink</h1>
            <h2 className="gulf-drink-subtitle">Discover lightning speed</h2>
            <div className="gulf-drink-links">
                <h3>{t("Connect with us")}</h3>
                <div className="gulf-drink-icons">
                  <a
                    href="https://www.facebook.com/gulfenergydrink/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF size={24} />
                  </a>
                  <a
                    href="https://www.instagram.com/gulfenergydrink"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram size={24} />
                  </a>
                  <a
                    href="https://youtube.com/@gulfenergydrink"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube size={24} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@gulf.energy.drink"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTiktok size={24} />
                  </a>
                  <a
                    href="https://x.com/GulfEnergyDrink"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaXTwitter />
                  </a>
                </div>
              </div>
            <p className="gulf-drink-description">
              Gulf Energy Drink is the best option for a rapid energy boost. Its essential ingredients help you stay energized and focused throughout your day.
              Use Gulf Energy Drink to stay revitalized and refreshed. Our product is powered by natural ingredients, low in sugar, and free of artificial colors.
            </p>
          </div>
        </div>

        <div className="product-item">
          <img src={gcColaSrc} alt="GC Cola" className="product-image" />
          <img src={downArrow} alt="Gulf Energy Drink" className="down-arrow" />
          <div className="gc-cola-content">
              <h1 className='line4'>GC Cola</h1>
              <h2 className='line5'>Bold Taste, Special Moments</h2>
              <div className="gc-cola-links">
                <h3>{t("Connect with us")}</h3>
                <div className="gc-cola-icons">
                  <a
                    href="https://www.facebook.com/gccolaofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF size={24} />
                  </a>
                  <a
                    href="https://www.instagram.com/gccola.world"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram size={24} />
                  </a>
                  <a
                    href="https://www.youtube.com/@GCColaOfficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube size={24} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@gccolaofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTiktok size={24} />
                  </a>
                  <a
                    href="https://x.com/GCColaOfficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaXTwitter />
                  </a>
                </div>
              </div>
              <p className='line6'>
                A crisp strong flavor delivered with each sip. GC Cola, a delicious soft drink made with premium ingredients, it's the ideal option for every occasion and provides a unique and satisfying taste. Enjoy GC Cola's distinctive flavor and brighten your day with every can.
              </p>
          </div>
        </div>

        <div className="product-item">
          <img src={juiceSrc} alt="Juices" className="product-image" />
          <img src={downArrow} alt="Gulf Energy Drink" className="down-arrow" />

          <div className="juice-content">
            <h1 className='juice-title'>Juices</h1>
            <h2 className='juice-subtitle'>Explore Our Natural Juice Flavours</h2>
            <div className='juices_desc_container'>
              <div className='juices_desc'>
                <ul>
                  <li>Increase nutrient intake</li>
                  <li>Keep us hydrated</li>
                  <li>Promote weight loss</li>
                  <li>Increase energy</li>
                  <li>Vitamin & Mineral Rich</li>
                  <li>Immune boosting</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="product-container">
          <img src={sunFlowerSrc} alt="Sunflower Oil" className="product-image" />
          <img src={downArrow} alt="Gulf Energy Drink" className="down-arrow" />

          <div className="product-content">
            <h1 className="product-title">Sunflower Oil</h1>
            <h2 className="product-subtitle">Refined Sunflower Oil</h2>
            <p className="product-description">
              Refined Sunflower oil is one of the most widely used types of oils in cooking. The refining process enhances its stability and quality to be adaptable to multiple culinary utilizations.
              
              <strong>In Frying:</strong> Its high smoke point ensures it can handle high temperatures without producing harmful components.
              
              <strong>In Baking:</strong> It is a healthier alternative to butter.
              
              <strong>In Marination:</strong> It has a mild flavor, making it ideal for various dressings and marinades.
            </p>
            <div className="product-benefits">
              <p>Besides its versatility in cooking, refined sunflower oil has many advantages:</p>
              <ul>
                <li>Source of Vitamin E</li>
                <li>Low in saturated fats</li>
                <li>Healthy skin, nourished hair</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="product-container olive-oil-box">
          <img src={oliveOilSrc} alt="Olive Oil" className="product-image olive-oil-image" />
          <img src={downArrow} alt="Gulf Energy Drink" className="down-arrow last" />

          <div className="olive-oil-content">
            <h1 className="product-title">Olive Oil</h1>
            <h2 className="olive-oil-subtitle">Discover the Authentic Taste of Our Cold Pressed Olive Oil</h2>
            <p className="product-description">
              <span className='title'>Olive oil is incredibly versatile in the kitchen:</span>
              <ul>
                <li>Perfect for drizzling on salads, soups, pasta, or vegetables.</li>
                <li>Great for sautéing, stir-frying, grilling, and baking.</li>
                <li>Used in cakes, breads, and pastries to add moisture and subtle flavor.</li>
              </ul>
            </p>
            <p className="product-description">
              <span className='title'>Olive oil is not only a flavorful addition to your meals but also offers various health benefits:</span>
              <ul>
                <li>Rich in oleic acid, which supports heart health.</li>
                <li>Contains vitamin E and polyphenols, which help fight inflammation.</li>
                <li>Promotes fullness and helps prevent fat accumulation when consumed in moderation.</li>
                <li>Aids digestion.</li>
              </ul>
            </p>
            <p className="product-note">To maintain the quality of olive oil, store it in a cool, dark location, away from direct heat.</p>
          </div>
        </div>

      </div >
    </>
  );
};

export default ProductsPage;