import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./home/home.js";
import ContactUs from "./contactUs/contactUs.js";
import AboutUs from "./aboutUs/aboutUs.js";
import Footer from "../components/footer/footer.js";
import GlobalPresence from "./globalPresence/globalPresence.js";
import ProductsPage from "./ourProducts/ourProducts.js";
import Navbar from "../components/header/navbar.js";
import OurPosition from "./ourPosition/ourPosition.js";
import JoinOurTeam from "./joinOurTeam/joinOurTeam.js";
import Career from "./Career/career.js";

function PageSwitch() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <> 
    <div className='header navbar navbar-expand-lg fixed-top' ><Navbar /></div>     
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/join-our-team" element={<JoinOurTeam />} />
        <Route path="/global-presence" element={<GlobalPresence />} />
        <Route path="/our-products" element={<ProductsPage/>} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-position" element={<OurPosition />} />
        <Route path="/career" element={<Career />} />
      </Routes>
      <Footer />
    </>
  );
}

export default PageSwitch;
