import React from 'react';
import "./ourPosition.css";
import BannerCard from '../../components/Card/BannerCard';
import OurPositionBanner from '../../assets/images/banners/open positions.png';
import backgroundLogoImage from '../../assets/images/Background Logo.png';
import rightBackgroundLogoImage from '../../assets/images/Right Background.png';

function OurPosition() {
  return (
    <>
      <BannerCard title="Open Positions" subtitle="Find the perfect job" image={OurPositionBanner} />

      
      <div className="our-position-container">
        <img src={rightBackgroundLogoImage} alt="Gulf Energy Drink" className="right-background-logo" />
        <img src={backgroundLogoImage} alt="Gulf Energy Drink" className="background-logo" />
          
          <div className="our-position-content">
            <h1 className="our-position-title">
              <span className="pink-title">STAY TUNED FOR</span>
              <span className="yellow-title">Upcoming Opportunities</span>
            </h1>
            <h2 className="our-position-subtitle">Thank you for your interest in joining our team!</h2>
          </div>
      </div>
    </>
  );
}

export default OurPosition;