import React from "react";
import "./career.css";

const Career = () => {

    return (
        <section className="career-section">
            <div className="career-container">
                <div className="career-container-divs">
                    <div className="container-xxl">
                        <h2 className="journey-title">Are you ready to join us on this exciting journey?</h2>
                        <div className="row">
                            <div class="journey-container col-md-6 col-sm-12 col-12">
                                <div class="journey-content">
                                    <div class="journey-text">
                                        <p>At our core, we strive for excellence not only in our products and services but also in fostering an inspiring and growth-driven workplace. We believe that our people are our greatest asset, and we’re committed to offering opportunities that enable individuals to grow, innovate, and achieve their highest potential.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="journey-container col-md-6 col-sm-12 col-12">
                                <div class="journey-content">
                                    <div class="journey-text">
                                        <p>When you join our team, you become part of a supportive and forward-thinking community where your talents and ambitions are recognized and cultivated. We welcome you to discover the wide range of career opportunities at GivTrade, where you can make an impact, develop your skills, and play an essential role in our journey toward lasting success.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Career;
