import React, { useState } from "react";
import "./joinOurTeam.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import joinOurTeamBanner from "../../assets/images/banners/join-our-team.png";
import contactFav from "../../assets/images/contact_fav.png";
import BannerCard from "../../components/Card/BannerCard";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";

const JoinOurTeam = () => {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    country: "",
    position: "",
    cv: null,
    cover_letter: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, cv: e.target.files[0] });
  };

  // toast.success("Thanks for sending us the email! We will reply to your email as soon as possible.");
  const submitData = (formDataToSend) => {
    return axios
      .post("https://api.shareholdersgcb.com/api/royal-food/join-team", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setFormData({
          fullName: "",
          email: "",
          message: "",
          country: "",
          position: "",
          cv: null,
          cover_letter: "",
        });
        setPhone("");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setIsFailed(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let formDataToSend = new FormData();
    formDataToSend.append("name", formData.fullName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", phone);
    formDataToSend.append("country", formData.country);
    formDataToSend.append("position", formData.position);
    formDataToSend.append("cv", formData.cv);
    formDataToSend.append("cover_letter", formData.cover_letter);

    // Show the toast with the promise handling
    toast.promise(
      submitData(formDataToSend),
      {
        pending: 'Processing your request...',
        success: 'Your request has been successfully sent!',
        error: 'Something went wrong. Please try again.'
      }
    ).finally(() => {
      setLoading(false); // Optional: to handle any state changes after the promise settles
    });
  };

  return (
    <>
      <BannerCard title="Contact Us" subtitle="Find Royal Food" image={joinOurTeamBanner} />

      <div className="contact-us-wrapper position-relative">
        <div className="contact-left-center-shadow"></div>
        <div className="contact-bg-logo">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <img src={contactFav} className="h-auto w-100 contact-bg-fav" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="joinOurTeam-container container-xxl">
          <div className="contact-form-container mb-5">
            <form className="contact-form" onSubmit={handleSubmit}>
              <h2 className="contact-form-title fw-bold mb-5">
                Are you ready to <span style={{ color: "#D50552" }}>join Our Team?</span>
              </h2>
              <div className="form-row">
                <div className="form-group">
                  <label>Full Name*</label>
                  <div className="input-box">
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Your Name"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Email*</label>
                  <div className="input-box">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email Address"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>Phone*</label>
                  <PhoneInput
                    country={"us"}
                    inputClass="phone-input-field bg-black"
                    containerClass="input-box"
                    dropdownClass="phone-dropdown"
                    value={phone}
                    onChange={setPhone}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Country Of Region*</label>
                  <div className="input-box">
                    <input
                      type="text"
                      name="country"
                      placeholder="Please select your country of residence"
                      value={formData.country}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group phone-group">
                <label>Desired Position*</label>
                <div className="input-box">
                  <input
                    type="text"
                    name="position"
                    placeholder="Let us know the role you’re applying for"
                    value={formData.position}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group phone-group">
                <label>Upload Your CV*</label>
                <div className="input-box">
                  <div className="upload-box" onClick={() => document.getElementById("fileUpload").click()}>
                    <div className="d-flex justify-content-center align-items-center gap-3">
                      <i className="fas fa-upload"></i>
                      <p className="mb-0">Upload Your CV</p>
                    </div>
                    <input type="file" id="fileUpload" accept=".pdf,.doc,.docx" onChange={handleFileChange} required/>
                    {
                      formData.cv && (
                        <div className="file-name mt-2">{formData.cv.name}</div>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Cover Letter*</label>
                <div className="input-box">
                <textarea
                  name="cover_letter"
                  placeholder="Kindly type your cover letter here"
                  value={formData.cover_letter}
                  onChange={handleChange}
                  required
                ></textarea></div>
              </div>

              <div className="d-flex justify-content-center">
                <button type="submit" className="submit-btn" disabled={loading}>
                  {loading ? <FaSpinner className="spinner" /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinOurTeam;