import React from 'react'
import energyDrink from '../../assets/images/gc-cola.png';
import oliveOil from '../../assets/images/oliveOil.png';
import oil from '../../assets/images/oil.png';
import juice from '../../assets/images/juce.png';
import cola from '../../assets/images/cola.png';

function TextAnimation() {

    const shuffleArray = (array)=> {
        return array.sort(() => Math.random() - 0.5)
    }
    const data = [
        {text:'SUN FLOWER OIL.',icon:oil},
        {text:'GC COLA.',icon:cola},
        {text:'JUICES.',icon:juice},
        {text:'GULF ENERGY DRINK.',icon:energyDrink},
        {text:'OLIVE OIL.',icon:oliveOil},
    ];
    return (
        <div className="animation-container">
                    <div className='left-to-right'>
                        <div class="text-box">
                            {
                                shuffleArray(data).map((item)=>(
                                    <div className='text' >
                                        <h1>{ item.text }</h1>
                                        <img src={item.icon} alt='' />
                                    </div>

                                ))
                            }
                        </div>
                        <div class="text-box">
                            {
                                shuffleArray(data).map((item)=>(
                                    <div className='text' >
                                        <h1>{ item.text }</h1>
                                        <img src={item.icon} alt='' />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='right-to-left'>
                        <div class="text-box">
                            {
                                data.map((item)=>(
                                    <div className='text' >
                                        <h1>{ item.text }</h1>
                                        <img src={item.icon} alt='' />
                                    </div>

                                ))
                            }
                        </div>
                        <div class="text-box">
                            {
                                data.map((item)=>(
                                    <div className='text' >
                                        <h1>{ item.text }</h1>
                                        <img src={item.icon} alt='' />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='left-to-right'>
                        <div class="text-box">
                            {
                                shuffleArray(data).map((item)=>(
                                    <div className='text' >
                                        <h1>{ item.text }</h1>
                                        <img src={item.icon} alt='' />
                                    </div>

                                ))
                            }
                        </div>
                        <div class="text-box">
                            {
                                shuffleArray(data).map((item)=>(
                                    <div className='text' >
                                        <h1>{ item.text }</h1>
                                        <img src={item.icon} alt='' />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                   
                </div>
    )
}

export default TextAnimation