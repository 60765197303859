import React from "react";
import "./home.css";
import ComingSoon from "../../components/coming_soon/coming_soon";

const Home = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default Home;
