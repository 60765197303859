import React from "react";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaYoutube, FaTiktok, FaInstagram } from "react-icons/fa";
import "./footer.css";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import frenchPdf from "../../assets/images/ROYAL_FOOD_FRENCH.pdf";
import englishPdf from "../../assets/images/ROYAL_FOOD_ENGLISH.pdf";
import arabicPdf from "../../assets/images/ROYAL_FOOD_ARABIC.pdf";

function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer">
        <div className="container-xxl container-lg"> 
          <div className="row justify-content-between">
            <div className="col-md-3 col-sm-12">
              <div>
                <img
                  src={require("../../assets/images/footer/footer.png")}
                  alt="GCB"
                  style={{ width: "240px", height: 'auto', marginBottom: "30px" }}
                />
              </div>
              <p style={{ textAlign: "left" }}>
                {t(
                  "Everything you require about our special products, job possibilities, policies and partnership, is just a click away."
                )}
              </p>
            </div>

            <div className="col-md-9 col-sm-12 row">
              <div className="col-md-3 col-sm-12">
                <h3>{t("Quick Link")}</h3>
                <ul>
                  <li>
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li>
                    <Link to="/our-products">{t("Products")}</Link>
                  </li>
                  <li>
                    <Link to="/global-presence">{t("Global Presence")}</Link>
                  </li>
                  <li>
                    <Link to="/about-us">{t("About Us")}</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">{t("Contact Us")}</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-12">
                <h3>{t("Careers")}</h3>
                <ul>
                  <li><Link to="/join-our-team">{t("Join Our Team")}</Link></li>
                  <li><Link to="/our-position">{t("Open Positions")}</Link></li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-12">
                <h3>{t("Legal")}</h3>
                <ul>
                  <li>
                    <a href="https://royalfb.com/PRIVACY-POLICY.pdf" target="_blank" rel="noopener noreferrer">{t("Privacy Policy")}</a></li>
                  <li>
                    <a href="https://royalfb.com/Terms-and-Conditions.pdf" target="_blank" rel="noopener noreferrer">
                      {t("Terms of Service")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-12">
                <h3>{t("Company Profile")}</h3>
                <ul>
                  <li>
                    <a href={englishPdf} download="ROYAL_FOOD_ENGLISH.pdf">{t("English")}</a>
                  </li>
                  <li>
                    <a href={frenchPdf} download="ROYAL_FOOD_FRENCH.pdf">{t("French")}</a>
                  </li>
                  <li>
                    <a href={arabicPdf} download="ROYAL_FOOD_ARABIC.pdf">{t("Arabic")}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-bottom-content">
            <div className="footer-subscribe">
              <label>{t("Join now")}</label>
              <label className="email-label">{t("Email")}</label>
              <div className="subscribe-box">
                <input type="email" placeholder="admin@royalfb.com" />
                <button className="custom-butto1n">
                  <img
                    src={require("../../assets/images/footer/arrow-shape.png")}
                    alt="Arrow"
                  />
                </button>
              </div>
            </div>
            <div className="under-links">
              <h3>{t("Connect with us")}</h3>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/tr.royalfood"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF size={24} />
                </a>
                <a
                  href="https://www.instagram.com/royalfoodtr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={24} />
                </a>
                <a
                  href="https://www.youtube.com/@RoyalFoodTR"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube size={24} />
                </a>
                <a
                  href="https://www.tiktok.com/@royalfoodtr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok size={24} />
                </a>
                <a
                  href="https://x.com/royalfoodtr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter />
                </a>
              </div>
            </div>
          </div>

        </div>
      </footer>
      <div className="footer-bottom">
        <p className="mb-0">&copy; {t("2025 Royal Food. All rights reserved.")} </p>
      </div>
    </>
  );
}

export default Footer;
