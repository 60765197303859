import React from 'react';
import './globalPresence.css'; // Import your CSS file for styling
import map from '../../assets/images/map.png'
import locationRed from '../../assets/images/location_red.png'
import locationYellow from '../../assets/images/location_yellow.png'
import globalPresence from '../../assets/images/banners/Presence.png'
import BannerCard from '../../components/Card/BannerCard';

const GlobalPresence = () => {
  const markers = [
    { id: 1, icon: locationRed, top: "41.6%", left: "55%", width: "40px", height: "50px" },
    { id: 2, icon: locationYellow, top: "44%", left: "56%", width: "22px", height: "25px" },
    { id: 3, icon: locationYellow, top: "42%", left: "57%", width: "22px", height: "25px" },
    { id: 4, icon: locationYellow, top: "54%", left: "44%", width: "22px", height: "25px" },
    { id: 5, icon: locationYellow, top: "46%", left: "45%", width: "22px", height: "25px" },
    { id: 6, icon: locationYellow, top: "44%", left: "49%", width: "22px", height: "25px" },
    { id: 76, icon: locationYellow, top: "48%", left: "52%", width: "22px", height: "25px" },
  ];
  return (
    <>
      <BannerCard title="Global Presence" subtitle="Our Location" image={globalPresence} />
      {/* <nav className="global-presence-sub-menu-container position-relative">
        <div className='bg-image'>
          <img src={globalPresence} className='w-100 h-auto d-none d-md-block' alt='' />
          <img src={globalPresenceMobile} className='w-100 h-auto d-block d-md-none' alt='' />
        </div>
        <div className="sub-menu-container"><span className="sub-menu-title">Global Presence</span> <span>Our Location</span></div>
      </nav> */}
      <div className="global-presence-container">
        {/* Map Section */}
        <div className="map-section">
          <div className="left-center-shadow"></div>
          <img src={map} alt="Global Presence Map" className='map-img' />
          {markers.map((marker) => (
            <img
              key={marker.id}
              src={marker.icon}
              alt="Location Marker"
              className="map-marker"
              style={{ top: marker.top, left: marker.left, width: marker.width, height: marker.height }}
            />
          ))}
        </div>

        {/* Text Section */}
        <div className="text-section p-3">
          <h1 className="international-network-heading">International Network</h1>
          <h2 className="international-network-heading global-presence-containerh2">For Agents</h2>
          <p className='text-white'>
            We are thrilled to share our ambitious plans to expand globally and establish a robust network of trustworthy
            partnerships, and provide a smooth experience for clients everywhere.
          </p>
          <p className='text-white'>
            We trust we can create a network of excellence that supports sustainable growth and open up exciting possibilities for all stakeholders by merging local knowledge with our global perspectives. With a focus on cooperation and shared success, we are sure that our global expansion will significantly impact how our industry evolves.
          </p>
        </div>
      </div>
    </>
  );
};

export default GlobalPresence;