import React from "react";
import "./aboutUs.css";
import aboutImage1 from "../../assets/images/about_img1.png";
import aboutImage2 from "../../assets/images/about_img2.png";
import aboutImage3 from "../../assets/images/about_img3.png";
import ceoImage from "../../assets/images/ceo_img.png";
import quote1 from "../../assets/images/quote1.png";
import quote2 from "../../assets/images/quote2.png";
import rectangle from "../../assets/images/rectangle.png";
import whiteCrown from "../../assets/images/crown-white.png";
import redCircleShadow from "../../assets/images/red-circle-shadow.png";
import redHalfShadow from "../../assets/images/red-shadow.png";
import highQuality from "../../assets/images/high_quality.png";
import recycle from "../../assets/images/recycle.png";
import liveChat from "../../assets/images/liveChat.png";
import clean from "../../assets/images/clean.png";
import star from "../../assets/images/star.png";
import BannerCard from "../../components/Card/BannerCard";
import AboutUsBanner from "../../assets/images/banners/About US.png";

const AboutUs = () => {

    return (
        <section className="aboutus-section">
            <BannerCard title="About Us" subtitle="About Royal Food" image={AboutUsBanner} />
            <div className="aboutUs-container">
                <div className="aboutUs-container-divs right-shadow">
                    <div className="container-xxl">
                        <div className="row">
                            <div class="vision-container col-md-6 col-sm-12 col-12">
                                <div class="vision-content">
                                    <div class="vision-image-box">
                                        <div class="vision-image">
                                            <img src={aboutImage1} alt="Vision" />
                                        </div>
                                        <h2><span>Our</span> Story</h2>
                                    </div>
                                    <div class="vision-text">
                                        <p>We started our journey in Malaysia, where we dominated the market. After delivering a high-quality product, we shortly established our reputation, began exporting overseas to lead the market which placed us among the top three edible oil exporting companies in Asia. With the help of our Founder and CEO Mr. Hani Asfar, we were able to grow our business and build a new factory in Turkey that will manufacture several new high-quality products to satisfy all our customers’ needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="vision-container col-md-6 col-sm-12 col-12">
                                <div class="vision-content">
                                    <div class="vision-image-box">
                                        <div class="vision-image">
                                            <img src={aboutImage2} alt="Vision" />
                                        </div>
                                        <h2><span>Our</span> Vision</h2>
                                    </div>
                                    <div class="vision-text">
                                        <p>By acquiring the latest technology, we aim to position our industry as a global supplier of high-end beverages and edible oil, providing our loyal customers with trusted products.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="vision-container col-sm-12">
                                <div class="vision-content">
                                    <div class="vision-image-box">
                                        <div class="vision-image">
                                            <img src={aboutImage3} alt="Vision" />
                                    </div>
                                        <h2><span>Our</span> Mission</h2>
                                    </div>
                                    <div class="vision-text">
                                        <p>At Royal Food, our mission is to deliver premium-quality food and beverages that enhance your dining experience. Our products, which range from energizing drinks and refreshing juices to healthy cooking essentials, are meticulously crafted with the finest ingredients and utmost care. Because we are committed to promoting sustainability and well-being, we offer a diverse selection of premium goods designed to meet a range of culinary and lifestyle needs, ensuring that every choice you make with us contributes to a more sustainable and healthy future.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ceo-section">
                    <img src={redHalfShadow} className="left-shadow" alt=""></img>
                    <div className="container-xxl">
                        <div className="row">
                            <div className="ceo-info-div">

                                {/* Foreground CEO Image */}
                                <div className="ceo-image">
                                    <img src={ceoImage} alt="CEO" className="ceo" />
                                    <div className="ceo-text-container">
                                        <div className="title">
                                            <img src={rectangle} alt="" />
                                            <p>MR. <b>HANI ASFAR</b> <br /> Our <b className="black-color">CEO</b></p>
                                        </div>
                                        <span className="right-quote"><img src={quote1} alt="CEO" /></span>
                                        <p>
                                            Driven by innovation and unity, we embrace challenges with determination.
                                            Our focus is on creating <span>meaningful impact</span>, guided by purpose and passion.
                                            Our commitment is to <span>quality</span> and the people we serve.
                                            Together, we turn visions <span>into reality</span>.
                                        </p>
                                        <span className="left-quote"><img src={quote2} alt="CEO" /></span>
                                    </div>
                                </div>

                                {/* Text Content */}
                            </div>
                            <div className="">
                                <div className="crown-div">
                                    <img src={redCircleShadow} className="right-image" alt="" />
                                    <img src={redCircleShadow} className="left-image" alt="" />
                                    <div className="white-crown-box">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="294" height="2" viewBox="0 0 294 2" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00683594 0.242676H293.993V1.75H-0.00683594V0.242676Z" fill="white"/>
                                        </svg>
                                        <img src={whiteCrown} alt="Crown" />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="294" height="2" viewBox="0 0 294 2" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00683594 0.242676H293.993V1.75H-0.00683594V0.242676Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <h2>Why Choose Royal Food</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-section">
                    <img src={redHalfShadow} className="right-shadow" alt="" />
                    <div className="container-xxl">
                        <div className="descriptions-divs">
                            <div className="description-div ">
                                <div className="description-container">
                                    <img src={highQuality} alt="Quality" />
                                    <h4 className="title">Premium Quality Ingredients</h4>
                                    <span>At Royal Food, we offer the highest quality products. Each item is designed to meet the highest standards of taste and nutrition.</span>
                                </div>

                                <div className="description-container">
                                    <img src={recycle} alt="Recycle" />
                                    <h4 className="title">Commitment to sustainability</h4>
                                    <span className="desc-text">Royal Food prioritizes environmentally friendly practices, including adopting eco-friendly packaging and reducing waste.  </span>
                                </div>
                            </div>
                            <div className="description-div">
                                <div className="description-container">
                                    <img src={liveChat} alt="Chat" />
                                    <h4 className="title">Outstanding Customer Service</h4>
                                    <span>Your satisfaction is our priority. We provide trustworthy, responsive customer support and a devotion to exceeding your expectations making your experience with Royal Food truly royal.</span>
                                </div>

                                <div className="description-container">
                                    <img src={clean} alt="Clean" />
                                    <h4 className="title">Strict Hygiene Standards</h4>
                                    <span className="desc-text">At Royal Food, we follow the highest hygiene and safety regulations, ensuring that all our products are safe and clean for consumption.</span>
                                </div>
                            </div>
                            <div className="description-div flex-center">
                                <div className="description-container">
                                    <img src={star} alt="Star" />
                                    <h4 className="title">Exceptional Taste</h4>
                                    <span className="desc-text">Our products are made with the highest ingredients to deliver a rich, satisfying, and memorable flavor that boost your enjoyment.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
