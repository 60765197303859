import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import headerLogo from "../../assets/images/headerLogo.png";
import { useTranslation } from "react-i18next";
import englishPdf from "../../assets/images/ROYAL_FOOD_ENGLISH.pdf";
import frenchPdf from "../../assets/images/ROYAL_FOOD_FRENCH.pdf";
import arabicPdf from "../../assets/images/ROYAL_FOOD_ARABIC.pdf";

function Navbar() {
  const { t } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isNavDark, setIsNavDark] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref for detecting outside click

  let lastScrollY = window.scrollY;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 10) {
        setIsScrollingDown(currentScrollY > lastScrollY);
        setIsNavDark(true);
      } else {
        setIsScrollingDown(false);
        setIsNavDark(false);
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to scroll to the certificate section
  const handleScrollToCertificates = () => {
    const certificateSection = document.getElementById("certificateSection");
    if (certificateSection) {
      certificateSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className={`container-fluid navbar_div ${isScrollingDown ? 'hide-nav' : `show-nav`} ${isNavDark ? 'bg-nav-dark' : ''}`}>
        <div className="toggle-div">
          <div className="toggle-div-img">
            <Link to="/" className="navbar-brand">
              <img src={headerLogo} alt="GCB" />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M6 6L18 18M6 18L18 6" stroke="#D50552" strokeWidth="2" />
              </svg> 
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M3 6H21M3 12H21M3 18H21" stroke="#D50552" strokeWidth="2" />
              </svg> 
            )}
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <Link to="/home" className={location.pathname === "/home" ? "nav-link active" : "nav-link"}>
              {t("home")}
            </Link>
            <Link to="/about-us" className={location.pathname === "/about-us" ? "nav-link active" : "nav-link"}>
              {t("about_us")}
            </Link>
            <Link to="/our-products" className={location.pathname === "/our-products" ? "nav-link active" : "nav-link"}>
              {t("our_products")}
            </Link>
            <Link to="/global-presence" className={location.pathname === "/global-presence" ? "nav-link active" : "nav-link"}>
              {t("global_presence")}
            </Link>
            <li className="nav-item">
              <button onClick={handleScrollToCertificates} className="nav-link download-button gold-btn">
                {t("certificate")}
              </button>
            </li>
            <Link to="/contact-us" className={location.pathname === "/contact-us" ? "nav-link active" : "nav-link"}>
              {t("contact_us")}
            </Link>
            <li className="nav-item dropdown" ref={dropdownRef}>
              <button
                className="nav-link dropdown-toggle custom-dropdown-button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {t("Company Profile")}
              </button>
              {isDropdownOpen && (
                <ul className="custom-dropdown-menu">
                  <li><a className="dropdown-item" href={englishPdf} download="ROYAL_FOOD_ENGLISH.pdf" onClick={() => setIsDropdownOpen(false)}>EN</a></li>
                  <li><a className="dropdown-item" href={frenchPdf} download="ROYAL_FOOD_FRENCH.pdf" onClick={() => setIsDropdownOpen(false)}>FR</a></li>
                  <li><a className="dropdown-item" href={arabicPdf} download="ROYAL_FOOD_ARABIC.pdf" onClick={() => setIsDropdownOpen(false)}>AR</a></li>
                </ul>
              )}
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item language-switcher">
              <button className="translate_btn" type="button">
                English
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
