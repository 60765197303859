import React from "react";
import PageSwitch from "./pages/page_switch";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <>
      <div className="App">
        <Router basename="/">
          <PageSwitch />
          <ToastContainer
            position="top-center"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="colored"
          />
        </Router>
      </div>
    </>
  );
}

export default App;
