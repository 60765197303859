import React, { useState, useEffect } from "react";
import "./coming_soon.css";
import turkish from "../../assets/images/turkish.jpg";
import cardBackground from "../../assets/images/card-background.svg";
import isoCertificate from "../../assets/images/iso-certificate.png";
import halalCertificate from "../../assets/images/halal-certificate.png";
import isoCertificatePdf from "../../assets/images/iso-certificate.pdf";
import halalCertificatePdf from "../../assets/images/halal-certificate.pdf";

const images = [turkish];

const ComingSoon = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="app-container-coming-soon">
      <div
        className="slide"
        style={{
          // backgroundImage: `url(${images[currentIndex]}) lightgray -30.75px 0px / 104.271% 100%`,
        }}
      >
        <img src={turkish} className="bg-image" alt="" />
          <div className="hero-content">
            <h2>
              <b>Bringing</b> <b className="quality-text">Quality</b>
            </h2>
            <div className="subtitle-box">
              <p className="mb-0">Taste, and Wellness</p>
              <p className="mb-0">in Every drop</p>
            </div>
            <div className="cta-buttons">
              <a href="/our-products" className="cta-button our-products">
                Our Products
              </a>
              <a href="/contact-us" className="cta-button get-started">
                <i
                  className="fas fa-play-circle"
                  style={{ marginRight: "0.5rem", color: "#d50552" }}
                ></i>
                Get Started
              </a>
            </div>
          </div>
          <img
            src={require("../../assets/images/crown-reflect.png")}
            alt="crown"
            className="crown-image" id="certificateSection"
          />
          <div className="certificate" >
            <div className="container-xxl">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="card-section" style={{ backgroundImage: `url(${cardBackground})` }}>
                    <div className="card-content">
                      <img src={isoCertificate} alt="Left Image" className="left-image" />
                      <div className="card-text">
                        <p>
                          Our ISO,GMP and HACCP Certification Document for Royal Food Commitment to Quality and International Standards
                        </p>
                        <a href={isoCertificatePdf} download="ISO_Certificate.pdf" className="download-button">
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="card-section" style={{ backgroundImage: `url(${cardBackground})` }}>
                    <div className="card-content">
                      <img src={halalCertificate} alt="Left Image" className="left-image" />
                      <div className="card-text">
                        <p>
                          Official Halal Certification Document for Royal Food Ensuring Quality and Compliance
                        </p>
                        <a href={halalCertificatePdf} download="HALAL_Certificate.pdf" className="download-button">
                          View Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
      </div>

      {/* <div className="button-container">
        <button
          className="button"
          onClick={() =>
            setCurrentIndex(
              (prevIndex) => (prevIndex - 1 + images.length) % images.length
            )
          }
        >
          <img
            src={require("../../assets/images/backButton.png")}
            alt="back"
            className="nav-button"
          />
        </button>
        <button className="button" onClick={nextSlide}>
          <img
            src={require("../../assets/images/nextButton.png")}
            alt="next"
            className="nav-button"
          />
        </button>
      </div> */}
    </div>
  );
};

export default ComingSoon;
